const availableTenants = ref([]);

export default () => {
    const useAuth = () => useNuxtApp().$auth;

    const authLogin = async (email, password) => {
        const response = await $fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                email,
                password,
            },
        });

        await useAuth().updateSession();

        const isSetupRequired = useCookie('isSetupRequired', {
            maxAge: 23 * 60 * 60 + 59 * 60 + 59,
        });
        isSetupRequired.value = response?.data?.restaurants?.length === 0;

        const redirectTo = response?.data?.isVerified === false ? '/email-confirmation' : '/dashboard';
        navigateTo(redirectTo);
    };

    const authFindTenants = async (email) => {
        const response = await $fetch('/api/auth/find-tenants', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                userIdentification: email,
            },
        });

        availableTenants.value = response;
        return response;
    };

    const authLogout = async () => {
        await $fetch('/api/auth/logout', {
            method: 'POST',
        });
        await useAuth().updateSession();
        availableTenants.value = [];
    };

    const getAvailableTenants = () => availableTenants;

    const authForgotPassword = async (email) => {
        const response = await $fetch('/api/auth/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                email,
            },
        });

        return response;
    };

    const authResetPassword = async (password, token) => {
        const response = await $fetch('/api/auth/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                password,
                token,
            },
        });

        return response;
    };

    return {
        useAuth,
        authLogin,
        authFindTenants,
        authLogout,
        authForgotPassword,
        authResetPassword,
        getAvailableTenants,
    };
};
